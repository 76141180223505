import React from 'react'
import { graphql } from 'gatsby'

import Wrap from 'components/Wrap'
import Overlay from 'components/Overlay'
import SwitchTheme from 'components/SwitchTheme'
import Fade from 'react-reveal/Fade'

import { ImageWrapStyled, ImageStripeStyled } from './ImageStyled'

export default function Image({ pageBuilder }) {
  const { image, imageposition, imageTheme } = pageBuilder

  return (
    <SwitchTheme name={imageTheme}>
      <Wrap padding="70px 0" display="flex" alignItems="center">
        <Overlay background="sectionImageBackground" />
        <ImageWrapStyled stripePosition={imageposition}>
          {image[0] && (
            <Fade bottom distance="10px" delay={200}>
              <ImageStripeStyled
                aspectRatio={16 / 9}
                fluid={image[0]}
                hasBoxShadow
                hasStripe
                tabletOnly
              />
              <ImageStripeStyled
                aspectRatio={10 / 3}
                fluid={image[0]}
                hasBoxShadow
                hasStripe
                desktopOnly
              />
            </Fade>
          )}
        </ImageWrapStyled>
      </Wrap>
    </SwitchTheme>
  )
}

export const query = graphql`
  fragment PageBuilderImageQuery on Craft_PageBuilderImage {
    image {
      ...CraftImageFluid
    }
    imageposition
    imageTheme
  }
`

import { styled } from "utils/emotion";
import media from "utils/mediaqueries";

import ImageStripe from "components/ImageStripe";

// "skateboard" variant (to get it work in IE too!)
export const ImageWrapStyled = styled("div")((props) => ({
  display: "block",
  height: "100%",
  width: "100%",
  margin: 0,
  zIndex: 1,
  [media("tabletFluid")]: {
    margin: props.imageposition === "right" ? "0 0 0 120px" : "0 120px 0 0",
  },
  position: "relative",
  "&:after": {
    display: "block",
    content: '""',
    position: "absolute",
    top: "100%",
    left: props.stripePosition === "left" && "0",
    right: props.stripePosition === "right" && "0",
    height: "8px",
    width: "50%",
    background: props.theme.imageStripeBackground,
  },

  "> div": {
    overflow: "hidden",
  },
}));

export const ImageStripeStyled = styled(ImageStripe)({}, (props) => ({
  display: props.desktopOnly ? "none" : "block",
  [media("tabletFluid")]: {
    display: props.tabletOnly ? "none" : "block",
  },
}));
